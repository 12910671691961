body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.botaoMenu {
  width: 100%;
}

.proximosJogos {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.caixaJogos {
  padding: 5px;
  align-items: center;
  height: 80px;
  border-radius: 5px;
  background: #e0e1e2;
  font-size: 12px;
  text-align: center;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-item {
  flex: 1;
  margin: 0 2px;
}

.first-item {
  margin-right: 2px !important;
  padding-left: 1rem !important
}

.last-item {
  margin-left: 2px !important;
  padding-right: 1rem !important
}

.caixaPesquisa {
  background: #e0e1e2;
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
}